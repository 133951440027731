<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <div class="form-body" style="padding-top: 60rem;padding-left: 40rem">
      <el-form ref="form" :model="formData" label-width="140rem" :rules="rules">
        <el-form-item label="学生姓名" prop="stu_id_card">
          <p>{{ $route.query.name }}</p>
        </el-form-item>
        <el-form-item label="原身份证号" prop="student_idcard_old">
          <el-input v-model="formData.student_idcard_old" maxlength="30" placeholder="请输入原身份证号"></el-input>
        </el-form-item>
        <el-form-item label="实际身份证号" prop="student_idcard">
          <el-input v-model="formData.student_idcard" maxlength="30" placeholder="请输入实际身份证号"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input resize="none" style="width: 790rem;" type="textarea" v-model="formData.remark" placeholder="请输入" rows="8" maxlength="100" show-word-limit=""></el-input>
        </el-form-item>

      </el-form>
    </div>


</edit-template>
</template>
<script>
import moment from "moment";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    const validatePass = (rule, value, callback) => {
      let reg15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;  //15位身份证正则
      let reg18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (reg15.test(value) || reg18.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的身份证号'));
      }
    };
    return {
      // 表单数据
      formData: {
        student_idcard_old: '',
        student_idcard: '',
        remark: '',
      },

      // 表单验证规则
      rules: {
        student_idcard_old: [{required: true, message: '请输入原身份证号', trigger: 'change'}, {validator: validatePass}],
        student_idcard: [{required: true, message: '请输入实际身份证号', trigger: 'change'}, {validator: validatePass}],
      }
    }
  },
  created() {

  },
  methods: {

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            id: this.$route.query.id,
            ...this.formData
          }
          this.$_register.post('api/recruit/renew/update', {...params}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '保存成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    },
  }
}
</script><style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

::v-deep .is-uploaded .el-upload {
  display: none;
}

.replace-img {
  width: 100rem;
  margin-left: 24rem;
  border: 1rem solid #999999;
  color: #666;
  border-radius: 6rem;
  height: 30rem;
  line-height: 30rem;
  text-align: center;
  cursor: pointer;
}
</style>
